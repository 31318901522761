const TabbedPage = ({ tab, children, isActive }) => {

  const active = isActive ? ' active' : '';
    return (
      <div className={"page page--" + tab + active}>
        {children}
      </div>
    );
}
export default TabbedPage;
